// Store
import Store from '@/Store/Global/Default'

// Class Export
export class AxiosManager {
	// Properties
	public static get AuthenticationHeader() {
		const token = Store.getters.getStoredAccessToken as string
		return { 'Authorization': `Bearer ${ token }` }
	}

	// Methods
	public static abortSignal(timeout = 0) {
		const abortController = new AbortController()
		setTimeout(() => abortController.abort(), timeout)
		return abortController.signal
	}
}