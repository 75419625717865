// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexWorkAreasModule<object>> {
	state: {
		allWorkAreas: [],
		workAreaPages: {},
		workAreaTotalPages: undefined
	},

	mutations: {
		addWorkArea: function(state, workarea) {
			state.allWorkAreas.push(workarea)
		},

		destroyWorkAreas: function(state) {
			state.allWorkAreas = []
			state.workAreaPages = {}
			state.workAreaTotalPages = undefined
		},

		destroyWorkAreasForPage: function(state, page) {
			delete state.workAreaPages[`${ page }`]
		},

		storeAllWorkAreas: function(state, response) {
			if (Array.isArray(response)) response.sort((a: any, b: any) => a.name > b.name ? 1 : -1)
			state.allWorkAreas = response
		},

		storeWorkAreasForPage: function(state, response) {
			state.workAreaPages[`${ response.page }`] = response.data
			state.workAreaTotalPages = response.totalPages
		},

		updateWorkArea: function(state, workarea) {
			VuexTools.updateRegistry(state, workarea, 'allWorkAreas', '_idWorkArea')
		}
	},

	getters: {
		getStoredAllWorkAreaById: (state) => (_idWorkArea: ObjectId) => {
			return state.allWorkAreas?.find((x: any) => x._idWorkArea === _idWorkArea)
		},

		getStoredAllWorkAreas: function(state) {
			return state.allWorkAreas
		},

		getStoredWorkAreasForPage: (state) => (page: number) => {
			return state.workAreaPages[`${ page }`]
		},

		getStoredWorkAreasTotalPages: function(state) {
			return state.workAreaTotalPages
		}
	},

	actions: {
		fetchAllWorkAreas: async function({ commit }) {
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await Axios.get(Server.Routes.WorkAreas.GetWorkAreas, { headers: AxiosManager.AuthenticationHeader, params: { _idAdminCompany } })
				commit('storeAllWorkAreas', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchWorkAreasForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters.getStoredWorkAreasForPage(params.page) as Array<any>
			const totalPages = Store.getters.getStoredWorkAreasTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const _params = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await Axios.get(Server.Routes.WorkAreas.GetWorkAreasByPage, { headers: AxiosManager.AuthenticationHeader, params: _params })
				const storages = response.data.body[0]
				commit('storeWorkAreasForPage', { data: storages.data, page: params.page, totalPages: storages.totalPages })
				return storages
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexWorkAreasModule<Document, State = VuexWorkAreasState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexWorkAreasMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexWorkAreasState<Document> {
	allWorkAreas: Array<Document>
	workAreaPages: { [key: string]: Array<Document> }
	workAreaTotalPages: number
}

interface VuexWorkAreasMutations<State, Document> extends Vuex.Mutations<State> {
	addWorkArea: (state: State, workarea: Document) => void
	storeAllWorkAreas: (state: State, response: Array<Document>) => void
	updateWorkArea: (state: State, workarea: Document) => void
}