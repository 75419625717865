// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexCheckListSettingsModule<any>> {
	state: {
		response: []
	},

	mutations: {
		addCheckListSetting: function(state, settings) {
			state.response.push(settings)
		},

		destroyCheckListSettings: function(state) {
			state.response = undefined
		},

		storeCheckListSettings: function(state, response) {
			state.response = response
		},

		updateCheckListSetting: function(state, setting) {
			VuexTools.updateRegistry(state, setting, 'response', '_idCheckListSetting')
		}
	},

	getters: {
		getStoredCheckListSettingByIdCheckListSetting: (state) => (_idCheckListSetting: ObjectId) => {
			return state.response?.find((x: any) => x._idCheckListSetting === _idCheckListSetting)
		},

		getStoredCheckListSettingByIdCompany: (state) => (_idCompany: ObjectId) => {
			return state.response?.find((x: any) => x._idCompany === _idCompany)
		},

		getStoredCheckListSettings: function(state) {
			return state.response
		}
	}
}

// Module Interfaces
interface VuexCheckListSettingsModule<Document, State = VuexCheckListSettingsState<Document>> {
	state: State
	mutations: VuexCheckListSettingsMutations<State, Document>
	getters: Vuex.Getters<State>
}

interface VuexCheckListSettingsState<Document> {
	response: Array<Document>
}

interface VuexCheckListSettingsMutations<State, Document> extends Vuex.Mutations<State> {
	addCheckListSetting: (state: State, settings: Document) => void
	storeCheckListSettings: (state: State, response: Array<Document>) => void
	updateCheckListSetting: (state: State, settings: Document) => void
}