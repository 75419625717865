// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexServicesModule<object>> {
	state: {
		response: undefined,
		servicePages: {},
		serviceTotalPages: undefined
	},

	mutations: {
		addService: function(state, service) {
			state.response.push(service)
		},

		destroyServices: function(state) {
			state.response = undefined
			state.servicePages = {}
			state.serviceTotalPages = undefined
		},

		destroyServicesForPage: function(state, page) {
			delete state.servicePages[`${ page }`]
		},

		storeServices: function(state, response) {
			state.response = response
		},

		storeServicesForPage: function(state, response) {
			state.servicePages[`${ response.page }`] = response.data
			state.serviceTotalPages = response.totalPages
		},

		updateService: function(state, service) {
			VuexTools.updateRegistry(state, service, 'response', '_idService')
		}
	},

	getters: {
		getStoredServiceById: (state) => (_idService: ObjectId) => {
			return state.response?.find((x: any) => x._idService === _idService)
		},

		getStoredServices: function(state) {
			return state.response
		},

		getStoredServicesForPage: (state) => (page: number) => {
			return state.servicePages[`${ page }`]
		},

		getStoredServicesTotalPages: function(state) {
			return state.serviceTotalPages
		}
	},

	actions: {
		fetchServices: async function({ commit }) {
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await Axios.get(Server.Routes.Services.GetServices, { headers: AxiosManager.AuthenticationHeader, params: { _idAdminCompany } })
				commit('storeServices', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchServicesForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters.getStoredServicesForPage(params.page) as Array<any>
			const totalPages = Store.getters.getStoredServicesTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const _params = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await Axios.get(Server.Routes.ServicesRequest.GetServicesAll, { headers: AxiosManager.AuthenticationHeader, params: _params })
				const services = response.data.body[0]
				commit('storeServicesForPage', { data: services.data, page: params.page, totalPages: services.totalPages })
				return services
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexServicesModule<Document, State = VuexServicesState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexServicesMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexServicesState<Document> {
	response: Array<Document>
	servicePages: { [key: string]: Array<Document> }
	serviceTotalPages: number
}

interface VuexServicesMutations<State, Document> extends Vuex.Mutations<State> {
	addService: (state: State, service: Document) => void
	storeServices: (state: State, response: Array<Document>) => void
	updateService: (state: State, service: Document) => void
}