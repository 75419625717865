// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios, { AxiosRequestConfig } from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Models
import { EquipmentDTO } from '@/Models/EquipmentDTO'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexEquipmentsModule<EquipmentDTO>> {
	state: {
		response: undefined,
		equipmentPages: {},
		equipmentTotalPages: undefined
	},

	mutations: {
		addEquipment: function(state, equipment) {
			state.response.push(equipment)
		},

		destroyEquipments: function(state) {
			state.response = undefined
			state.equipmentPages = {}
			state.equipmentTotalPages = undefined
		},

		destroyEquipmentsForPage: function(state, page) {
			delete state.equipmentPages[`${ page }`]
		},

		storeEquipments: function(state, response) {
			state.response = response
		},

		storeEquipmentsForPage: function(state, response) {
			state.equipmentPages[`${ response.page }`] = response.data
			state.equipmentTotalPages = response.totalPages
		},

		updateEquipment: function(state, equipment) {
			VuexTools.updateRegistry(state, equipment, 'response', '_idEquipment')
		}
	},

	getters: {
		getStoredEquipmentById: (state) => (_idEquipment: ObjectId) => {
			return state.response?.find((x: any) => x._idEquipment === _idEquipment)
		},

		getStoredEquipments: function(state) {
			return state.response
		},

		getStoredEquipmentsForPage: (state) => (page: number) => {
			return state.equipmentPages[`${ page }`]
		},

		getStoredEquipmentsTotalPages: function(state) {
			return state.equipmentTotalPages
		}
	},

	actions: {
		fetchEquipments: async function({ commit }) {
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await Axios.get(Server.Routes.Equipments.GetEquipments, { headers: AxiosManager.AuthenticationHeader, params: { _idAdminCompany } })
				commit('storeEquipments', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchEquipmentsForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters.getStoredEquipmentsForPage(params.page) as Array<any>
			const totalPages = Store.getters.getStoredEquipmentsTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Parametros Petición Axios.
			const { _idAdminCompany } = Store.getters.getStoredUser as any
			const _params = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
			const axiosConfig = { headers: AxiosManager.AuthenticationHeader, params: _params } as AxiosRequestConfig

			// Realizar Petición a Servidor.
			try {
				const response = await Axios.get(Server.Routes.Equipments.GetEquipmentsByPage, axiosConfig)
				const storages = response.data.body[0]
				commit('storeEquipmentsForPage', { data: storages.data, page: params.page, totalPages: storages.totalPages })
				return storages
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexEquipmentsModule<Document, State = VuexEquipmentsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexEquipmentsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexEquipmentsState<Document> {
	response: Array<Document>
	equipmentPages: { [key: string]: Array<Document> }
	equipmentTotalPages: number
}

interface VuexEquipmentsMutations<State, Document> extends Vuex.Mutations<State> {
	addEquipment: (state: State, equipment: Document) => void
	storeEquipments: (state: State, response: Array<Document>) => void
	updateEquipment: (state: State, equipment: Document) => void
}