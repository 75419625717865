// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Models
import { CompanyDTO } from '@/Models/CompanyDTO'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexCompaniesModule<CompanyDTO>> {
	state: {
		response: undefined,
		companyPages: {},
		companyTotalPages: undefined
	},

	mutations: {
		addCompany: function(state, company) {
			state.response.push(company)
		},

		destroyCompanies: function(state) {
			state.response = undefined
			state.companyPages = {}
			state.companyTotalPages = undefined
		},

		destroyCompaniesForPage: function(state, page) {
			delete state.companyPages[`${ page }`]
		},

		storeCompanies: function(state, response) {
			state.response = response
		},

		storeCompaniesForPage: function(state, response) {
			state.companyPages[`${ response.page }`] = response.data
			state.companyTotalPages = response.totalPages
		},

		updateCompany: function(state, company) {
			VuexTools.updateRegistry(state, company, 'response', '_idCompany')
		}
	},

	getters: {
		getStoredCompanyById: (state) => (_idCompany: ObjectId) => {
			return state.response?.find((x: any) => x._idCompany === _idCompany)
		},

		getStoredCompanies: function(state) {
			return state.response
		},

		getStoredCompaniesForPage: (state) => (page: number) => {
			return state.companyPages[`${ page }`]
		},

		getStoredCompaniesTotalPages: function(state) {
			return state.companyTotalPages
		}
	},

	actions: {
		fetchCompanies: async function({ commit }) {
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await Axios.get(Server.Routes.Companies.GetCompanies, { headers: AxiosManager.AuthenticationHeader, params: { _idAdminCompany } })
				commit('storeCompanies', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchCompaniesForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters.getStoredCompaniesForPage(params.page) as Array<any>
			const totalPages = Store.getters.getStoredCompaniesTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const _params = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await Axios.get(Server.Routes.Companies.GetCompaniesByPage, { headers: AxiosManager.AuthenticationHeader, params: _params })
				const companies = response.data.body[0]
				commit('storeCompaniesForPage', { data: companies.data, page: params.page, totalPages: companies.totalPages })
				return companies
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexCompaniesModule<Document, State = VuexCompaniesState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexCompaniesMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexCompaniesState<Document> {
	response: Array<Document>
	companyPages: { [key: string]: Array<Document> }
	companyTotalPages: number
}

interface VuexCompaniesMutations<State, Document> extends Vuex.Mutations<State> {
	addCompany: (state: State, company: Document) => void
	storeCompanies: (state: State, response: Array<Document>) => void
	updateCompany: (state: State, company: Document) => void
}