// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Models
import { ModuleDTO } from '@/Models/ModuleDTO'

// Module Export
export default <VuexSystemsModule<ModuleDTO>> {
	state: {
		response: undefined
	},

	mutations: {
		destroySystems: function(state) {
			state.response = undefined
		},

		storeSystems: function(state, response: Array<any>) {
			for (const s of response) {
				s.display = true
				for (const m of s.modules) {
					m.display = true
					m.indeterminate = { read: false, write: false }
					m.privileges = { read: true, write: true }
				}
			}	
			state.response = response
		}
	},

	getters: {
		getStoredSystems: function(state) {
			return state.response
		}
	}
}

// Module Interfaces
interface VuexSystemsModule<Document, State = VuexSystemsState<Document>> {
	state: State
	mutations: VuexSystemsMutations<State, Document>
	getters: Vuex.Getters<State>
}

interface VuexSystemsState<Document> {
	response: Array<Document>
}

interface VuexSystemsMutations<State, Document> extends Vuex.Mutations<State> {
	storeSystems: (state: State, response: Array<Document>) => void
}